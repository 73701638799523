import React, { Component } from "react";
import "./Home.css";

class Home extends Component {

    render() {
        return (
            <div className="Home">
                <div className="lander">
                    <h1>Metaphoto</h1>
                    <p>Keep track of all that photographic metadata</p>
                </div>
            </div>
        );
    }
}

export default Home;
